export function toLocaleDateTime(
  value: string | null | undefined,
  isIso = false
) {
  if (value == null) {
    return "";
  }

  if (value == undefined) {
    return "";
  }

  if (isIso) {
    value = value + "Z";
  }

  const date = new Date(value);
  return date.toLocaleString();
}
