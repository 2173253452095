import { Role } from "@/models/Role";

export function getIconFromRole(role: Role): string {
  switch (role) {
    case Role.Administrator:
      return "mdi-shield-account";
    case Role.Dispatcher:
      return "mdi-phone";
    case Role.Carrier:
      return "mdi-domain";
    case Role.Analyst:
      return "mdi-poll";
    case Role.Driver:
      return "mdi-car";
    case Role.Assistant:
      return "mdi-human-wheelchair";
    default:
      return "mdi-account";
  }
}
