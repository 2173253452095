import { JwtToken, LoginResponse, RefreshToken } from "@/api";

export class Store<T> {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  set(value: T | null | undefined) {
    const json = JSON.stringify(value);
    localStorage.setItem(this.key, json);
  }

  get(): T | null {
    const json = localStorage.getItem(this.key);

    if (json === null) {
      return null;
    }

    return JSON.parse(json);
  }
}

const tokenStore = new Store<JwtToken>("ApiToken");
const userStore = new Store<LoginResponse>("CurrentUser");
const navigationStore = new Store<boolean>("NavigationSetting");
const calendarViewStore = new Store<number>("SelectedCalendarView");
const shownSlotsStore = new Store<number>("NumberOfShownSlots");
const showCancelledStore = new Store<boolean>("ShowCancelled");
const showUnsatisfiedStore = new Store<boolean>("ShowUnsatisfied");
const showShiftsStore = new Store<boolean>("ShowShifts");
const unplannedRidesSortByStore = new Store<number>("RidesSortBy");
const unplannedRidesOrderAscStore = new Store<boolean>("RidesOrderAsc");
const hideUnplannedStore = new Store<boolean>("HideUnplanned");
const refreshTokenStore = new Store<RefreshToken>("RefreshToken");
const refreshStore = new Store<Date>("RefreshStore");

export {
  tokenStore,
  userStore,
  navigationStore,
  calendarViewStore,
  shownSlotsStore,
  showCancelledStore,
  showShiftsStore,
  unplannedRidesSortByStore,
  unplannedRidesOrderAscStore,
  hideUnplannedStore,
  refreshTokenStore,
  refreshStore,
  showUnsatisfiedStore,
};
