
import { Component, Vue } from "vue-property-decorator";

import NavigationDrawer from "@/components/Shared/NavigationDrawer.vue";
import CallWidget from "@/components/Shared/CallWidget.vue";
import SideAlerts from "@/components/Shared/SideAlerts.vue";
import ConfirmDialog from "@/components/Shared/ConfirmDialog.vue";
import {
  initGoogleMaps,
  isCurrentUserInAnyRole,
  isCurrentUserInRole,
} from "./utils";
import { Role } from "./models/Role";

@Component({
  components: {
    NavigationDrawer,
    CallWidget,
    SideAlerts,
    ConfirmDialog,
  },
})
export default class App extends Vue {
  isMobile = false;

  Role = Role;

  get isNavMenuHidden() {
    return this.$route.meta?.isNavMenuHidden || false;
  }

  get backgroundColor() {
    return this.$vuetify.theme.currentTheme.primary;
  }

  mounted() {
    initGoogleMaps();
  }

  isCurrentUserInAnyRole(roles: Role[]): boolean {
    return isCurrentUserInAnyRole(roles);
  }

  onResize() {
    this.isMobile = window.innerWidth < 768;
  }
}
