var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-navigation-drawer',{staticClass:"transparent py-3",staticStyle:{"height":"100%","padding-left":"10px"},attrs:{"hide-overlay":"","floating":"","mini-variant":_vm.isNavigationDrawerMiniVariant,"app":"","permanent":"","mini-variant-width":"80"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex"},[(false)?_c('call-widget',{staticClass:"mt-4"}):_vm._e()],1)]},proxy:true}])},[_c('div',{staticClass:"primary lighten-1 rounded-xl d-flex flex-column pa-1",staticStyle:{"height":"100%"}},[_c('v-list',{staticClass:"rounded-xl mb-auto",staticStyle:{"overflow-y":"scroll","overflow-x":"hidden"},attrs:{"dense":"","nav":"","flat":""}},[_c('v-list-item-group',{staticClass:"mt-2"},[(_vm.currentUser)?_c('v-list-item',{attrs:{"to":{ name: 'profile' },"ripple":false}},[_c('v-list-item-avatar',{staticClass:"mx-auto my-auto"},[_c('v-img',{attrs:{"src":_vm.avatarImage}})],1)],1):_vm._e(),(!_vm.isNavigationDrawerMiniVariant)?_c('v-list-item',{attrs:{"to":{ name: 'profile' },"ripple":false}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-center font-weight-bold white--text"},[_vm._v(" "+_vm._s(_vm.currentUser?.fullName)+" ")])],1)],1):_vm._e()],1),_c('v-divider',{class:{
          'my-4': _vm.isNavigationDrawerMiniVariant,
          'my-1': !_vm.isNavigationDrawerMiniVariant,
        },style:({
          'border-color': _vm.isNavigationDrawerMiniVariant
            ? 'var(--v-primary-lighten2)'
            : '',
        })}),_vm._l((_vm.filteredItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"href":item.link,"link":"","to":item.view ? { name: item.view, params: item.params } : ''}},[_c('v-tooltip',{staticStyle:{"background-color":"red"},attrs:{"right":"","disabled":!_vm.isNavigationDrawerMiniVariant},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-icon',_vm._g(_vm._b({class:{
                'ml-1': _vm.isNavigationDrawerMiniVariant,
                'ml-6': !_vm.isNavigationDrawerMiniVariant,
                'mr-4': !_vm.isNavigationDrawerMiniVariant,
              }},'v-list-item-icon',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isNavigationDrawerMiniVariant
                    ? 'primary lighten-5'
                    : 'white',"small":!_vm.isNavigationDrawerMiniVariant,"normal":_vm.isNavigationDrawerMiniVariant}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text font-weight-bold"},[_vm._v(_vm._s(item.title))])],1)],1)})],2),(_vm.canSeeRides && _vm.shouldSeeRides)?_c('div',{staticClass:"mx-auto",staticStyle:{"width":"95%"}},[(!_vm.loadingRides)?_c('v-tooltip',{staticClass:"mt-auto",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"rounded py-1 mb-4 white--text",class:{
              'mini-text': _vm.isNavigationDrawerMiniVariant,
              'not-mini-text': !_vm.isNavigationDrawerMiniVariant,
              'stats-bad': _vm.rides < 0.98,
              'stats-ok': _vm.rides >= 0.98 && _vm.rides < 0.99,
              'stats-good': _vm.rides >= 0.99,
              'can-load-stats': _vm.canLoadManually,
              'cannot-load-stats': !_vm.canLoadManually,
            },staticStyle:{"font-weight":"500","width":"100%","text-align":"center"},on:{"click":function($event){return _vm.loadRideStatistics(true)}}},on),[_vm._v(" "+_vm._s(Math.round(100 * _vm.rides))+"% ")])]}}],null,false,566734811)},[_c('span',[_vm._v("Aktuální odklikanost")])]):_vm._e(),(_vm.loadingRides)?_c('v-progress-circular',{staticClass:"mx-auto mb-4",staticStyle:{"width":"100%"},attrs:{"indeterminate":"","color":"primary lighten-5","size":"27","width":"3"}}):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"1300"},model:{value:(_vm.isOrderDialogOpen),callback:function ($$v) {_vm.isOrderDialogOpen=$$v},expression:"isOrderDialogOpen"}},[_c('v-card',{staticClass:"pa-6"},[_c('order-detail',{attrs:{"dialog":true,"update":_vm.update},on:{"close":_vm.closeDialog}})],1)],1),_c('div',{class:{
        'd-flex align-end flex-column': !_vm.isNavigationDrawerMiniVariant,
        'd-flex align-center flex-column': _vm.isNavigationDrawerMiniVariant,
      }},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.changeHideMenu}},[_c('v-icon',{attrs:{"color":"primary lighten-5","small":""}},[_vm._v(" "+_vm._s(_vm.isNavigationDrawerMiniVariant ? "mdi-arrow-right-drop-circle-outline" : "mdi-arrow-left-drop-circle-outline")+" ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }