export function getWeekdayName(weekday: number): string {
  switch (weekday) {
    case 1:
      return "po";
    case 2:
      return "út";
    case 3:
      return "st";
    case 4:
      return "čt";
    case 5:
      return "pá";
    case 6:
      return "so";
    case 0:
      return "ne";
    default:
      return "";
  }
}
