export default class ConfirmDialogOptions {
  constructor(color = "primary", width = 290, zIndex = 200) {
    this.color = color;
    this.width = width;
    this.zIndex = zIndex;
  }

  color = "primary";
  width = 290;
  zIndex = 200;
}
