import NotEmptyRule from "./NotEmptyRule";

export const ValidationRules = {
  NotEmpty: NotEmptyRule,
};

export function getUserAvatarRules() {
  const avatarMaxUploadSize = 100000;
  return [
    (v: File) =>
      !v ||
      v.size < avatarMaxUploadSize ||
      `Soubor musí mít méně než ${avatarMaxUploadSize / 1000000} MB`,
  ];
}
