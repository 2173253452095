import { RoleModel } from "@/api";

export enum Role {
  Administrator = "Administrator",
  Dispatcher = "Dispatcher",
  Carrier = "Carrier",
  Analyst = "Analyst",
  Driver = "Driver",
  Assistant = "Assistant",
}

export class RoleTranslate {
  public static getRoleTranslate(role: string): string {
    switch (role) {
      case Role.Administrator: {
        return "Administrátor";
      }
      case Role.Dispatcher: {
        return "Dispečer";
      }
      case Role.Carrier: {
        return "Dopravce";
      }
      case Role.Analyst: {
        return "Analytik";
      }
      case Role.Driver: {
        return "Řidič";
      }
      case Role.Assistant: {
        return "Asistent";
      }
      default: {
        return "Neznámá role";
      }
    }
  }

  public static getRolesTranslate(roles: Role[]): string[] {
    return roles.map(this.getRoleTranslate);
  }

  public static getRolesModelTranslate(roles: RoleModel[]): RoleModel[] {
    return roles.map((x) => ({
      name: this.getRoleTranslate(x.name ?? ""),
      carrierIds: x.carrierIds ?? [],
    }));
  }
}
