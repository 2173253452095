import { OrderType } from "@/models/Orders/OrderEnums";

export function getTextFromOrderType(value: OrderType | undefined) {
  if (value == undefined) {
    return "";
  }
  switch (value) {
    case OrderType.OneTime:
      return "Jednorázová";
    case OrderType.Repeated:
      return "Opakovaná";
    default:
      return "";
  }
}
