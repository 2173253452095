import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { isCurrentUserInAnyRole, isUserAuthenticated } from "@/utils";
import { Role } from "@/models/Role";

const CustomersView = () => import("@/views/CustomersView.vue");
const CustomerView = () => import("@/views/CustomerView.vue");
const NotFoundView = () => import("@/views/NotFoundView.vue");
const InDevelopmentView = () => import("@/views/InDevelopmentView.vue");
const LoginView = () => import("@/views/LoginView.vue");
const UsersView = () => import("@/views/UsersView.vue");
const UserView = () => import("@/views/UserView.vue");
const CreatePasswordView = () => import("@/views/CreatePasswordView.vue");
const CarriersView = () => import("@/views/CarriersView.vue");
const VehiclesView = () => import("@/views/VehiclesView.vue");
const VehicleView = () => import("@/views/VehicleView.vue");
const ForgotPasswordView = () => import("@/views/ForgotPasswordView.vue");
const ForgotPasswordFinishView = () =>
  import("@/views/ForgotPasswordFinishView.vue");
const EnumsView = () => import("@/views/EnumsView.vue");
const VehicleTypesView = () => import("@/views/VehicleTypesView.vue");
const CurrentMapView = () => import("@/views/CurrentMapView.vue");
const SlotsView = () => import("@/views/SlotsView.vue");
const OperatingDaysView = () => import("@/views/OperatingDaysView.vue");
const SlotAdministrationView = () =>
  import("@/views/SlotAdministrationView.vue");
const PageNotFoundView = () => import("@/views/NotFoundView.vue");
const ProfileView = () => import("@/views/UserProfileView.vue");
const SlotPlanningView = () => import("@/views/SlotPlanningView.vue");
const RidePlanningView = () => import("@/views/RidePlanningView.vue");
const OrdersView = () => import("@/views/OrdersView.vue");
const OrderView = () => import("@/views/OrderView.vue");
const MobileAppView = () => import("@/views/MobileAppView.vue");
const RidesView = () => import("@/views/RidesView.vue");
const RideView = () => import("@/views/RideView.vue");

const SlotInstanceDetails = () => import("@/views/SlotInstanceDetails.vue");
const SlotInstanceDetail = () => import("@/views/SlotInstanceDetail.vue");
const OperationView = () => import("@/views/OperationView.vue");
const AddressesView = () => import("@/views/AddressesView.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: CurrentMapView,
  },
  {
    path: "/prihlaseni",
    name: "login",
    component: LoginView,
    meta: {
      isNavMenuHidden: true,
      allowAnonymous: true,
      title: "Přihlášení",
    },
  },
  {
    path: "/uzivatele",
    name: "users",
    component: UsersView,
    children: [
      {
        path: "/uzivatele/:id",
        name: "user",
        component: UserView,
        meta: {
          roles: [Role.Administrator, Role.Dispatcher, Role.Carrier],
          title: "Uživatelé",
        },
      },
    ],
    meta: {
      roles: [Role.Administrator, Role.Dispatcher, Role.Carrier],
      title: "Uživatelé",
    },
  },
  {
    path: "/ciselniky",
    name: "enums",
    component: EnumsView,
    children: [
      {
        path: "/ciselniky/typ-vozidla",
        name: "vehicle-types",
        component: VehicleTypesView,
        meta: {
          roles: [Role.Administrator, Role.Dispatcher],
          title: "Typy vozidel",
        },
      },
      {
        path: "/ciselniky/adresy",
        name: "addresses",
        component: AddressesView,
        meta: {
          roles: [Role.Administrator, Role.Dispatcher],
          title: "Adresy",
        },
      },
      {
        path: "/ciselniky/adresy",
        name: "adresses",
        component: InDevelopmentView,
        meta: {
          roles: [Role.Administrator, Role.Dispatcher],
          title: "Číselníky",
        },
      },
    ],
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Číselníky",
    },
  },
  {
    path: "/sloty/planovani/:date",
    name: "slot-planning",
    component: SlotPlanningView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher, Role.Carrier],
      title: "Sloty",
    },
  },
  {
    path: "/sloty",
    name: "slots",
    component: SlotsView,
    children: [
      {
        path: "/sloty/provozni-dny",
        name: "operating-days",
        component: OperatingDaysView,
        meta: {
          roles: [Role.Administrator, Role.Dispatcher],
          title: "Sloty",
        },
      },
      {
        path: "/sloty/administrace",
        name: "slot-administration",
        component: SlotAdministrationView,
        meta: {
          roles: [Role.Administrator, Role.Dispatcher],
          title: "Sloty",
        },
      },
    ],
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Sloty",
    },
  },
  {
    path: "/dopravci",
    name: "carriers",
    component: CarriersView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Dopravci",
    },
  },
  {
    path: "/zakaznici",
    name: "customers",
    component: CustomersView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Zákazníci",
    },
  },
  {
    path: "/vozidla",
    name: "vehicles",
    component: VehiclesView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher, Role.Carrier],
      title: "Vozidla",
    },
  },
  {
    path: "/vozidlo/:id",
    name: "vehicle",
    component: VehicleView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher, Role.Carrier],
      title: "Vozidla",
    },
  },
  {
    path: "/zakaznik/:id",
    name: "customer",
    component: CustomerView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Zákazníci",
    },
  },
  {
    path: "/provoz",
    name: "current-map",
    component: CurrentMapView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher, Role.Analyst],
      title: "Provoz",
    },
  },
  {
    path: "/vytvoreni-hesla",
    name: "create-password",
    component: CreatePasswordView,
    meta: {
      isNavMenuHidden: true,
      allowAnonymous: true,
      title: "Přihlášení",
    },
  },
  {
    path: "/zapomenute-heslo-dokonceni",
    name: "forgot-password-finish",
    component: ForgotPasswordFinishView,
    meta: {
      isNavMenuHidden: true,
      allowAnonymous: true,
      title: "Přihlášení",
    },
  },
  {
    path: "/zapomenute-heslo",
    name: "forgot-password",
    component: ForgotPasswordView,
    meta: {
      isNavMenuHidden: true,
      allowAnonymous: true,
      title: "Přihlášení",
    },
  },
  {
    path: "/objednavky",
    name: "orders",
    component: OrdersView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Objednávky",
    },
  },
  {
    path: "/objednavka/:id",
    name: "order",
    component: OrderView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Objednávka",
    },
  },
  {
    path: "/board",
    name: "rides-planning",
    component: RidePlanningView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Board",
    },
    children: [
      {
        path: "/board/:date",
        name: "rides-planning-day",
        component: RidePlanningView,
        meta: {
          roles: [Role.Administrator, Role.Dispatcher],
          title: "Board",
        },
      },
    ],
  },
  {
    path: "/detail-slotu/:date",
    name: "slot-instance-details",
    component: SlotInstanceDetails,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Detail slotu",
    },
    children: [
      {
        path: "/detail-slotu/:date/:id",
        name: "slot-instance-detail",
        component: SlotInstanceDetail,
        meta: {
          roles: [Role.Administrator, Role.Dispatcher],
          title: "Detail slotu",
        },
      },
    ],
  },

  {
    path: "/jizdy",
    name: "rides",
    component: RidesView,
    meta: {
      roles: [
        Role.Administrator,
        Role.Dispatcher,
        Role.Carrier,
        Role.Driver,
        Role.Assistant,
      ],
      title: "Jízdy",
    },
  },
  {
    path: "/jizdy/:id",
    name: "ride",
    component: RideView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Jízdy",
    },
  },

  {
    path: "/vykony",
    name: "operation",
    component: OperationView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher, Role.Analyst],
      title: "Výkony",
    },
    children: [
      {
        path: "/vykony/:month",
        name: "operation-month",
        component: OperationView,
        meta: {
          roles: [
            Role.Administrator,
            Role.Dispatcher,
            Role.Carrier,
            Role.Analyst,
          ],
          title: "Výkony",
        },
      },
    ],
  },

  {
    path: "/hovory",
    name: "calls",
    component: InDevelopmentView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Hovory",
    },
  },
  {
    path: "/sankce",
    name: "sanctions",
    component: InDevelopmentView,
    meta: {
      roles: [Role.Administrator, Role.Dispatcher],
      title: "Sankce",
    },
  },
  {
    path: "/stale-ve-vyvoji",
    name: "in-development",
    component: InDevelopmentView,
  },
  {
    path: "/stranka-nenalezena",
    name: "page-not-found",
    component: PageNotFoundView,
  },
  {
    path: "/mobilni-aplikace",
    name: "mobile-app",
    component: MobileAppView,
  },
  {
    path: "/profil",
    name: "profile",
    component: ProfileView,
    meta: {
      roles: [
        Role.Administrator,
        Role.Analyst,
        Role.Assistant,
        Role.Dispatcher,
        Role.Carrier,
        Role.Driver,
        Role.Assistant,
      ],
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let title = "App Bezba";

  if (to.meta?.title) {
    title += ` | ${to.meta.title}`;
  }

  document.title = title;

  if (!to.meta?.allowAnonymous && !isUserAuthenticated()) {
    return next({ name: "login" });
  }

  if (
    to.name != "page-not-found" &&
    !to.meta?.allowAnonymous &&
    to.meta?.roles != undefined &&
    !isCurrentUserInAnyRole(to.meta?.roles)
  ) {
    return next({ name: "page-not-found" });
  }

  return next();
});

export default router;
