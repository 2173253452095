import { HandicapEquipment } from "@/models/Customer/CustomerEnums";

export function getIconFromEquipment(
  value: HandicapEquipment | null | number
): string {
  if (value == 0) {
    return "mdi-account-question";
  }

  switch (value) {
    case HandicapEquipment.Walking:
      return "mdi-shoe-print";
    case HandicapEquipment.Wheelchair:
      return "mdi-wheelchair-accessibility";
    case HandicapEquipment.ElectricWheelchair:
      return "mdi-wheelchair";
    case HandicapEquipment.Carriage:
      return "mdi-baby-carriage";
    case null:
      return "mdi-account-child";
    default:
      return "";
  }
}
