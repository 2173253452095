import { Escort } from "@/models/Orders/OrderEnums";

export function getEscortTranslation(escort: Escort | null) {
  switch (escort) {
    case null:
      return "";
    case Escort.ChildLittle:
      return "dítě do 6";
    case Escort.Child:
      return "dítě";
    case Escort.Adult:
      return "dospělý";
    default:
      return escort;
  }
}
