
import { Component, Vue, Watch } from "vue-property-decorator";
import CallWidget from "@/components/Shared/CallWidget.vue";
import OrderDetail from "@/components/Orders/OrderDetail.vue";
import { navigationStore, userStore } from "@/store";
import { eventBus, Events } from "@/utils/event-bus";
import {
  ImageApi,
  LoginResponse,
  RideApi,
  RideStatisticsCountModel,
} from "@/api";
import {
  getDefaultApiConfig,
  isCurrentUserInAnyRole,
  isUserAuthenticated,
} from "@/utils";
import AvatarPlaceholder from "@/assets/avatar-placeholder.png";
import { Role } from "@/models/Role";
import { OrderInstanceState } from "@/models/Orders/OrderEnums";

@Component({
  name: "NavigationDrawer",
  components: {
    CallWidget,
    OrderDetail,
  },
})
export default class NavigationDrawer extends Vue {
  rideApi = new RideApi(getDefaultApiConfig());

  isMounted = false;
  loadingRides = false;
  rides = 0;
  stats: RideStatisticsCountModel[] | null = null;

  items = [
    {
      title: "Provoz",
      icon: "mdi-map",
      view: "current-map",
      rights: this.$router.match({ name: "current-map" }).meta?.roles || [],
    },
    {
      title: "Board",
      icon: "mdi-view-dashboard-variant",
      view: "rides-planning",
      rights: this.$router.match({ name: "rides-planning" }).meta?.roles || [],
    },
    {
      title: "Objednávky",
      icon: "mdi-clipboard-list",
      view: "orders",
      rights: this.$router.match({ name: "orders" }).meta?.roles || [],
    },
    {
      title: "Sloty",
      icon: "mdi-calendar-month",
      view: "slot-administration",
      rights:
        this.$router.match({ name: "slot-administration" }).meta?.roles || [],
    },
    {
      title: "Plánování slotů",
      icon: "mdi-calendar-account",
      view: "slot-planning",
      params: { date: new Date().toISOString().substring(0, 10) },
      rights:
        this.$router.match({
          name: "slot-planning",
        }).meta?.roles || [],
    },
    {
      title: "Jízdy",
      icon: "mdi-play",
      view: "rides",
      rights: this.$router.match({ name: "rides" }).meta?.roles || [],
    },
    {
      title: "Zákazníci",
      icon: "mdi-human-wheelchair",
      view: "customers",
      rights: this.$router.match({ name: "customers" }).meta?.roles || [],
    },
    {
      title: "Dopravci",
      icon: "mdi-domain",
      view: "carriers",
      rights: this.$router.match({ name: "carriers" }).meta?.roles || [],
    },
    {
      title: "Vozidla",
      icon: "mdi-car",
      view: "vehicles",
      rights: this.$router.match({ name: "vehicles" }).meta?.roles || [],
    },
    {
      title: "Uživatelé",
      icon: "mdi-account",
      view: "users",
      rights: this.$router.match({ name: "users" }).meta?.roles || [],
    },
    {
      title: "Výkony",
      icon: "mdi-gauge",
      view: "operation-month",
      params: { month: new Date().toISOString().substring(0, 7) },
      rights: this.$router.match({ name: "operation" }).meta?.roles || [],
    },

    {
      title: "Reporting",
      icon: "mdi-chart-arc",
      link: "https://app.powerbi.com/view?r=eyJrIjoiYWUwMjljMjAtNzk1ZC00Y2UzLWEzMjYtMWEyNTk0MGVkYTQwIiwidCI6ImVlYzdhMTRiLTQ5YmUtNDllZC05NDMxLWI3Y2ViYjYzZTIzOCIsImMiOjl9",
      rights: [Role.Administrator, Role.Dispatcher, Role.Analyst],
    },

    {
      title: "Číselníky",
      icon: "mdi-cog",
      view: "addresses",
      rights: this.$router.match({ name: "addresses" }).meta?.roles || [],
    },
    {
      title: "Hovory",
      icon: "mdi-phone",
      view: "calls",
      show: false,
      rights: this.$router.match({ name: "calls" }).meta?.roles || [],
    },
    {
      title: "Sankce",
      icon: "mdi-alert",
      view: "sanctions",
      show: false,
      rights: this.$router.match({ name: "sactions" }).meta?.roles || [],
    },
    {
      title: "Reporting",
      icon: "mdi-chart-donut",
      view: "in-development",
      show: false,
      rights: this.$router.match({ name: "in-development" }).meta?.roles || [],
    },
  ];

  update = 0;

  currentUser = userStore.get();
  avatarImage = AvatarPlaceholder;

  isNavigationDrawerMiniVariant = false;

  imageApi = new ImageApi(getDefaultApiConfig());

  isOrderDialogOpen = false;

  canLoadManually = true;

  @Watch("currentUser.AvatarImageId", { immediate: true })
  onAvatarImageChanged() {
    if (isUserAuthenticated()) {
      this.loadImage();
    }
  }

  onUserLoggedIn(currentUser: LoginResponse) {
    this.currentUser = currentUser;
  }

  mounted() {
    eventBus.$on(Events.UserLoggedIn, this.onUserLoggedIn);

    var navStoreValue = navigationStore.get();
    this.isNavigationDrawerMiniVariant =
      navStoreValue != null ? navStoreValue : false;

    this.loadRideStatistics();

    this.isMounted = true;
  }

  changeHideMenu() {
    this.isNavigationDrawerMiniVariant = !this.isNavigationDrawerMiniVariant;
    navigationStore.set(this.isNavigationDrawerMiniVariant);
  }

  closeDialog() {
    this.isOrderDialogOpen = false;
  }

  openOrderDialog() {
    this.update++;
    this.isOrderDialogOpen = true;
  }

  async loadImage() {
    if (!this.currentUser?.avatarImageId) {
      this.avatarImage = AvatarPlaceholder;
      return;
    }

    var response = await this.imageApi.getImage(
      this.currentUser.avatarImageId,
      { responseType: "blob" }
    );

    this.avatarImage = URL.createObjectURL(response.data);
  }

  get filteredItems() {
    return this.items.filter((x) => {
      if (x.show === false) {
        return false;
      }

      return isCurrentUserInAnyRole(x.rights);
    });
  }

  get canSeeRides() {
    return isCurrentUserInAnyRole([Role.Administrator, Role.Dispatcher]);
  }

  get shouldSeeRides() {
    return (
      this.stats != null &&
      (this.stats.find((x) => x.state == OrderInstanceState.Finished)?.count ??
        0) +
        (this.stats.find((x) => x.state == OrderInstanceState.Ongoing)?.count ??
          0) +
        (this.stats.find((x) => x.state == OrderInstanceState.DidNotShowUp)
          ?.count ?? 0) +
        (this.stats.find((x) => x.state == OrderInstanceState.Planned)?.count ??
          0) >
        0
    );
  }

  allowManualLoad() {
    this.canLoadManually = true;
  }

  async loadRideStatistics(manual = false) {
    if (!this.canSeeRides) {
      return;
    }

    if (!this.canLoadManually) {
      return;
    }

    this.loadingRides = true;

    if (manual) {
      this.canLoadManually = false;
      setTimeout(this.allowManualLoad, 30000);

      await new Promise((r) => setTimeout(r, 500));
    }

    const now = new Date();

    var result = await this.rideApi.getRideStatistics(
      `${this.filters.toIsoDate(now).substring(0, 10)} 0:00:00`,
      now.toISOString()
    );

    if (result.data) {
      this.stats = result.data;

      const total =
        (this.stats.find((x) => x.state == OrderInstanceState.Finished)
          ?.count ?? 0) +
        (this.stats.find((x) => x.state == OrderInstanceState.Ongoing)?.count ??
          0) +
        (this.stats.find((x) => x.state == OrderInstanceState.DidNotShowUp)
          ?.count ?? 0) +
        (this.stats.find((x) => x.state == OrderInstanceState.Planned)?.count ??
          0);

      this.rides =
        ((this.stats.find((x) => x.state == OrderInstanceState.Finished)
          ?.count ?? 0) +
          (this.stats.find((x) => x.state == OrderInstanceState.Ongoing)
            ?.count ?? 0) +
          (this.stats.find((x) => x.state == OrderInstanceState.DidNotShowUp)
            ?.count ?? 0)) /
        (total == 0 ? 1 : total);
      this.loadingRides = false;
    }

    if (this.isMounted && !manual) {
      setTimeout(this.loadRideStatistics, 300000);
    }
  }
}
