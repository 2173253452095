import { HubConnection } from "@aspnet/signalr";
import Vue from "vue";

export default class RidesHub extends Vue {
  connection: HubConnection;
  connected = false;

  onConnectedCallback: (() => void) | null = null;

  constructor(connection: HubConnection) {
    super();
    this.connection = connection;
  }

  subscribeDatesAsync(dates: string[]): Promise<any> {
    return this.connection.invoke("SubscribeDatesAsync", dates);
  }

  unsubscribeDatesAsync(dates: string[]): Promise<any> {
    return this.connection.invoke("UnsubscribeDatesAsync", dates);
  }
}
