import { OrderInstanceState } from "@/models/Orders/OrderEnums";

export function orderInstanceStateToText(state: OrderInstanceState | any) {
  switch (state) {
    case OrderInstanceState.Unplanned:
      return "Nenaplánovaná";
    case OrderInstanceState.Planned:
      return "Naplánovaná";
    case OrderInstanceState.Ongoing:
      return "Probíhající";
    case OrderInstanceState.Cancelled:
      return "Zrušená";
    case OrderInstanceState.DidNotShowUp:
      return "Nedorazil";
    case OrderInstanceState.Finished:
      return "Dokončená";
    default:
      return "";
  }
}
