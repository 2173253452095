import { HandicapEquipment } from "@/models/Customer/CustomerEnums";

export function getColorFromEquipment(
  value: HandicapEquipment | null | undefined
): string {
  switch (value) {
    case HandicapEquipment.Walking:
      return "warning";
    case HandicapEquipment.Wheelchair:
      return "primary";
    case HandicapEquipment.ElectricWheelchair:
      return "accent";
    case HandicapEquipment.Carriage:
      return "error";
    case null:
      return "secondary";
    default:
      return "";
  }
}
