export function toLocaleTime(
  value: string | null | undefined | Date,
  includeSeconds = false
) {
  if (value == null || value === undefined) {
    return "";
  }

  const date = new Date(value);
  const timeStr = date.toLocaleTimeString();

  if (includeSeconds) {
    return timeStr;
  }

  return timeStr.substring(0, timeStr.lastIndexOf(":"));
}
