import { OrderType } from "@/models/Orders/OrderEnums";

export function getColorFromOrderType(value: OrderType | undefined) {
  if (value == undefined) {
    return "secondary";
  }
  switch (value) {
    case OrderType.OneTime:
      return "primary";
    case OrderType.Repeated:
      return "accent";
    default:
      return "secondary";
  }
}
