
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import AddressDialog from "@/components/Customers/AddressDialog.vue";
import RideTimeRangeInput from "@/components/Shared/RideTimeRangeInput.vue";

import { getGoogleMapsAPI } from "gmap-vue";

import TravelMode from "@/models/GoogleMaps/TravelMode";

import {
  AddressModel,
  CustomerApi,
  EnumsApi,
  GetCustomersModel,
  GetOrderResponse,
  SlotInstanceModel,
  HandicapEquipment,
  OrderApi,
  OrderDayModel,
  SaveOrderRequest,
  SlotinstanceApi,
} from "@/api";
import { AxiosError } from "axios";

import { getDefaultApiConfig, parseAxiosError } from "@/utils";
import {
  Escort,
  OrderRepetitionFrequency,
  OrderSaveMode,
  OrderState,
  OrderType,
  TimeType,
} from "@/models/Orders/OrderEnums";
import { debounce } from "vue-debounce";
import AcceptanceTypeToggle from "@/components/Orders/AcceptanceTypeToggle.vue";
import OrderTypeToggle from "@/components/Orders/OrderTypeToggle.vue";
import { totalmem } from "os";

@Component({
  name: "OrderDetail",
  components: {
    AddressDialog,
    RideTimeRangeInput,
    AcceptanceTypeToggle,
    OrderTypeToggle,
  },
})
export default class OrderDetail extends Vue {
  @Prop({ default: false })
  dialog: boolean;

  @Prop({ default: undefined })
  passedOrder: GetOrderResponse;

  @Prop({ default: 0 })
  update: number;

  @Prop({ default: undefined })
  customerId: number | undefined;

  id = 0;

  isRepeated = false;

  loading = false;
  loadingCustomers = false;
  loadingSlots = false;
  loadingCancel = false;
  loadingDelete = false;
  loadingUpdate = false;

  isAddressDialogOpen = false;
  isFromAddress = false;
  addressDialogOpen = 0;

  isCancelDialogOpen = false;
  isUpdateSinceDialogOpen = false;

  isInstantiablePropertyChanged = false;

  errors: string[] = [];

  orderApi = new OrderApi(getDefaultApiConfig());
  customerApi = new CustomerApi(getDefaultApiConfig());
  slotInstanceApi = new SlotinstanceApi(getDefaultApiConfig());
  enumsApi = new EnumsApi(getDefaultApiConfig());

  orderTypes = OrderType;
  orderStates = OrderState;

  selectedAddress: AddressModel | null = {
    city: "",
    postCode: "",
    street: "",
    streetNumber: "",
    name: "",
  };

  order: SaveOrderRequest | any = {
    id: 0,
    acceptanceType: undefined,
    customerId: 0,

    escortDog: false,
    escortLuggage: false,
    addressFrom: {
      id: 0,

      city: "",
      street: "",
      streetNumber: "",
      postCode: "",

      addressType: 2,
    },
    addressTo: {
      id: 0,

      city: "",
      street: "",
      streetNumber: "",
      postCode: "",

      addressType: 2,
    },
    orderType: OrderType.OneTime,
    repetitionFrequency: OrderRepetitionFrequency.All,
    state: 1,
    slotInstanceId: undefined,
    slotInstanceReturnId: undefined,
    date: undefined,
    dateTo: undefined,
    noteInternal: "",
    noteDriver: "",

    times: [
      {
        isReturn: false,
        weekday: 0,
        pickup: undefined,
        dropoff: undefined,
        primaryTime: 1,
      },
      {
        isReturn: true,
        weekday: 0,
        pickup: undefined,
        dropoff: undefined,
        primaryTime: 1,
      },
    ],
    updateSince: "",
  };

  cancelDate = "";

  customers: GetCustomersModel[] = [];
  toSlotInstances: SlotInstanceModel[] = [];
  returnSlotInstances: SlotInstanceModel[] = [];
  handicapEquipment: HandicapEquipment[] = [];
  originalOrderState = OrderState.New;

  repetitionFrequencies = [
    { text: "Každý týden", value: OrderRepetitionFrequency.All },
    { text: "Sudé týdny", value: OrderRepetitionFrequency.EvenWeeks },
    { text: "Liché týdny", value: OrderRepetitionFrequency.OddWeeks },
  ];

  debouncedLoadCustomers = debounce(this.loadCustomers, 300);

  // eslint-disable-next-line no-undef
  directionService: google.maps.DirectionsService | null = null;

  @Watch("update", { immediate: true, deep: true })
  async onValueChanged() {
    if (this.order && this.order.id && this.order.id > 0) {
      await this.loadOrder();
    }

    if (this.dialog) {
      this.order = {
        id: 0,
        acceptanceType: 1,
        customerId: 0,

        escortDog: false,
        escortLuggage: false,
        addressFrom: {
          id: 0,

          city: "",
          street: "",
          streetNumber: "",
          postCode: "",

          addressType: 2,
        },
        addressTo: {
          id: 0,

          city: "",
          street: "",
          streetNumber: "",
          postCode: "",

          addressType: 2,
        },
        orderType: OrderType.OneTime,
        repetitionFrequency: OrderRepetitionFrequency.All,
        state: 1,
        slotInstanceId: undefined,
        slotInstanceReturnId: undefined,
        date: undefined,
        dateTo: undefined,
        noteInternal: "",
        noteDriver: "",

        times: [
          {
            isReturn: false,
            weekday: 0,
            pickup: undefined,
            dropoff: undefined,
            primaryTime: 1,
          },
          {
            isReturn: true,
            weekday: 0,
            pickup: undefined,
            dropoff: undefined,
            primaryTime: 1,
          },
        ],
        updateSince: "",
      };
    }
  }

  get areAddressesFilledIn() {
    return (
      this.filters.toAddressText(this.order.addressFrom) != "" &&
      this.filters.toAddressText(this.order.addressTo) != ""
    );
  }

  get escortOptions() {
    var escortOptions: {
      key: string;
      handicapEquipment: HandicapEquipment | null;
      escort: Escort | null;
    }[] = [
      {
        escort: null,
        handicapEquipment: null,
        key: "-",
      },
    ];

    var escortEnumValues = Object.keys(Escort).filter(
      (k) => !isNaN(Number(Escort[k as keyof typeof Escort]))
    );

    escortEnumValues.forEach((value) => {
      this.handicapEquipment.forEach((equip) => {
        escortOptions.push({
          key: `${value}-${equip.id}`,
          handicapEquipment: equip,
          escort: Escort[value as keyof typeof Escort],
        });
      });
    });

    return escortOptions;
  }

  get google() {
    return getGoogleMapsAPI();
  }

  get isReturn() {
    if (!this.order.times) {
      return false;
    }

    return (
      this.order.times?.some((x) => x.isReturn && x.dropoff && x.pickup) == true
    );
  }

  get saveModes() {
    var modes = [];

    if (this.order.state == OrderState.New) {
      modes.push({ text: "Rozpracovaná", value: OrderSaveMode.Draft });

      if (!this.dialog) {
        modes.push({
          text: "Rozpracovaná a duplikovat",
          value: OrderSaveMode.DraftAndDuplicate,
        });
      }

      modes.push({ text: "Umožnit plánování", value: OrderSaveMode.Plan });

      if (!this.dialog) {
        modes.push({
          text: "Umožnit plánování a duplikovat",
          value: OrderSaveMode.PlanAndDuplicate,
        });
      }

      return modes;
    }

    if (this.order.state != OrderState.Cancelled)
      modes.push(
        { text: "Uložit", value: OrderSaveMode.Plan },
        {
          text: "Uložit a duplikovat",
          value: OrderSaveMode.PlanAndDuplicate,
        }
      );

    return modes;
  }

  get startAndEndDate() {
    var startDate = new Date(this.order.date);
    var endDate = new Date(this.order.date);

    if (this.order.date == "") {
      startDate = new Date();
      endDate = new Date();
    }

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    return { startDate, endDate };
  }

  get returnTime() {
    if (this.order.orderType == OrderType.Repeated || !this.order.times) {
      return {
        pickup: "",
        dropoff: "",
        isReturn: true,
        primaryTime: TimeType.Dropoff,
        weekday: 0,
      };
    }

    var toTime = this.order.times.find((x) => x.isReturn == true);

    if (!toTime) {
      return {
        pickup: "",
        dropoff: "",
        isReturn: true,
        primaryTime: TimeType.Dropoff,
        weekday: 0,
      };
    }

    return toTime;
  }

  set returnTime(value: OrderDayModel) {
    if (this.order.orderType == OrderType.Repeated || !this.order.times) {
      return;
    }

    var idx = this.order.times.findIndex((x) => x.isReturn == true);

    if (idx === -1) {
      if (value != null && value.dropoff && value.pickup) {
        this.order.times.push(value);
        return;
      }

      return;
    }

    this.order.times.splice(idx, 1, value);
  }

  get toTime() {
    if (this.order.orderType == OrderType.Repeated || !this.order.times) {
      return {
        pickup: "",
        dropoff: "",
        isReturn: false,
        primaryTime: TimeType.Dropoff,
        weekday: 0,
      };
    }

    var toTime = this.order.times.find((x) => x.isReturn == false);

    if (!toTime) {
      return {
        pickup: "",
        dropoff: "",
        isReturn: false,
        primaryTime: TimeType.Dropoff,
        weekday: 0,
      };
    }

    return toTime;
  }

  set toTime(value: OrderDayModel) {
    if (this.order.orderType == OrderType.Repeated || !this.order.times) {
      return;
    }

    var idx = this.order.times.findIndex((x) => x.isReturn == false);

    if (idx === -1) {
      if (value != null && value.dropoff && value.pickup) {
        this.order.times.push(value);
        return;
      }

      return;
    }

    this.order.times.splice(idx, 1, value);
  }

  get canCancel() {
    return (
      this.order.state == OrderState.Planned ||
      this.order.state == OrderState.PartialyPlanned
    );
  }

  get canDelete() {
    return (
      this.order.state == OrderState.New ||
      this.order.state == OrderState.Unplanned ||
      this.order.state == OrderState.Cancelled
    );
  }

  get canSave() {
    return (
      this.order.state == OrderState.New ||
      this.order.state == OrderState.Unplanned ||
      this.order.state == OrderState.PartialyPlanned ||
      this.order.state == OrderState.Planned
    );
  }

  mounted() {
    if (!this.dialog) {
      this.id = Number(this.$route.params.id);
    }

    //var minDate = this.filters.toIsoDate(new Date());
    //this.order.date = minDate;
    //this.order.dateTo = minDate;

    if (this.passedOrder) {
      // this.order = this.passedOrder;
    }

    this.initPage();
  }

  initPage() {
    if (this.id > 0) {
      this.loadOrder();
    }

    if (this.id == 0 && this.customerId) {
      this.order.customerId = Number(this.customerId);

      this.order.acceptanceType = 0;

      this.loadCustomer(this.order.customerId);
    }

    this.loadHandicapEquipment();
  }

  onInstantiablePropertyChanged() {
    if (this.order.state != OrderState.New) {
      this.isInstantiablePropertyChanged = true;
    }
  }

  setEscortOption(opt: {
    key: string;
    handicapEquipment: HandicapEquipment | null;
    escort: Escort | null;
  }) {
    this.order.escort = opt.escort || undefined;
    this.order.escortHandicapEquipmentId = opt.handicapEquipment?.id;

    this.onInstantiablePropertyChanged();
  }

  getSelectedEscortOption() {
    return {
      key: "",
      escort: this.order.escort || null,
      handicapEquipment:
        this.handicapEquipment.find(
          (x) => x.id == this.order.escortHandicapEquipmentId
        ) || null,
    };
  }

  getWeekDayTime(weekday: number, isReturn: boolean): OrderDayModel {
    var time = this.order.times?.find(
      (x) => x.isReturn == isReturn && x.weekday == weekday
    );

    if (!time) {
      return {
        dropoff: undefined,
        isReturn: isReturn,
        pickup: undefined,
        primaryTime: TimeType.Dropoff,
        weekday: weekday,
      };
    }

    return time;
  }

  setWeekDayTime(weekday: number, isReturn: boolean, time: OrderDayModel) {
    var timeIdx = this.order.times?.findIndex(
      (x) => x.isReturn == isReturn && x.weekday == weekday
    );

    if (!timeIdx || timeIdx == -1) {
      return;
    }

    this.order.times?.splice(timeIdx, 1, time);
  }

  orderTypeChanged() {
    this.order.times = [];

    if (this.order.orderType == OrderType.Repeated) {
      for (let weekday = 0; weekday < 7; weekday++) {
        this.order.times.push(
          {
            dropoff: undefined,
            isReturn: false,
            pickup: undefined,
            primaryTime: TimeType.Pickup,
            weekday: weekday,
          },
          {
            dropoff: undefined,
            isReturn: true,
            pickup: undefined,
            primaryTime: TimeType.Pickup,
            weekday: weekday,
          }
        );
      }

      this.order.date = undefined;
      this.order.dateTo = undefined;

      return;
    }

    this.order.times = [
      {
        dropoff: undefined,
        isReturn: false,
        pickup: undefined,
        primaryTime: TimeType.Pickup,
        weekday: 0,
      },
      {
        dropoff: undefined,
        isReturn: true,
        pickup: undefined,
        primaryTime: TimeType.Pickup,
        weekday: 0,
      },
    ];

    //this.onDateChanged(this.filters.toIsoDate(new Date()));
  }

  weekDayToWeekDayAbbr(weekday: number) {
    var baseDate = new Date(Date.UTC(2017, 0, 2));

    baseDate.setDate(baseDate.getDate() + weekday);

    return baseDate
      .toLocaleDateString(undefined, { weekday: "short" })
      .toUpperCase();
  }

  onDateChanged(date: string) {
    if (this.order.orderType == OrderType.OneTime) {
      this.order.date = date;
      this.order.dateTo = date;

      this.loadSlotInstances(true);
      this.loadSlotInstances(false);
    }
  }

  onCancelDateChanged(date: string) {
    this.cancelDate = date;
  }

  onUpdateSinceChanged(date: string) {
    this.order.updateSince = date;
  }

  openAddressDialog(address: AddressModel, isFromAddress: boolean) {
    this.isAddressDialogOpen = true;

    this.selectedAddress = JSON.parse(JSON.stringify(address));
    this.isFromAddress = isFromAddress;

    this.addressDialogOpen += 1;
  }

  async saveAddress(address: AddressModel) {
    this.isAddressDialogOpen = false;

    if (address && address.customer?.customerId) {
      var result = await this.customerApi.getCustomer(
        address.customer?.customerId
      );

      if (result.data) {
        if (result.data.addresses.some((x) => x.id == address.id)) {
          let index = result.data.addresses.findIndex(
            (x) => x.id == address.id
          );

          result.data.addresses.splice(index, 1, address);

          try {
            await this.customerApi.saveCustomer(result.data);
          } catch (e) {
            console.error(e);

            this.$sideAlert(
              "V kartě zákazníka je chyba, zkontrolujte údaje",
              "warning"
            );
          }
        }
      }
    }

    if (this.isFromAddress) {
      this.order.addressFrom = this.selectedAddress;
    } else {
      this.order.addressTo = this.selectedAddress;
    }

    if (
      (this.order.addressFrom.googlePlaceId &&
        this.order.addressTo.googlePlaceId) ||
      (this.order.addressFrom.lat &&
        this.order.addressFrom.lng &&
        this.order.addressTo.lat &&
        this.order.addressTo.lng)
    ) {
      this.getDirections();
    }

    this.selectedAddress = null;
  }

  swapAddresses() {
    var addressFrom = JSON.parse(JSON.stringify(this.order.addressFrom));

    this.order.addressFrom = this.order.addressTo;
    this.order.addressTo = addressFrom;

    if (
      (this.order.addressFrom.googlePlaceId &&
        this.order.addressTo.googlePlaceId) ||
      (this.order.addressFrom.lat &&
        this.order.addressFrom.lng &&
        this.order.addressTo.lat &&
        this.order.addressTo.lng)
    ) {
      this.getDirections();
    }
  }

  getOrderAddMissingTimes() {
    if (!this.order.times) {
      return;
    }

    if (
      this.order.orderType == OrderType.OneTime &&
      this.order.times.length <= 1
    ) {
      this.order.times?.push({
        dropoff: undefined,
        isReturn: true,
        pickup: undefined,
        primaryTime: TimeType.Dropoff,
        weekday: 0,
      });

      return;
    }

    for (let weekday = 0; weekday < 7; weekday++) {
      var currentTime = this.order.times.filter((x) => x.weekday == weekday);

      if (currentTime.findIndex((x) => x.isReturn == true) === -1) {
        this.order.times.push({
          dropoff: undefined,
          isReturn: true,
          pickup: undefined,
          primaryTime: TimeType.Pickup,
          weekday: weekday,
        });
      }

      if (currentTime.findIndex((x) => x.isReturn == false) === -1) {
        this.order.times.push({
          dropoff: undefined,
          isReturn: false,
          pickup: undefined,
          primaryTime: TimeType.Pickup,
          weekday: weekday,
        });
      }
    }
  }

  closeOrderCreationDialog() {
    if (this.dialog) {
      this.id = 0;

      this.$emit("close");
    }
  }

  toggleEscortDog() {
    this.order.escortDog = !this.order.escortDog;

    this.onInstantiablePropertyChanged();
  }

  toggleEscortLuggage() {
    this.order.escortLuggage = !this.order.escortLuggage;

    this.onInstantiablePropertyChanged();
  }

  async getDirections() {
    if (!this.google) {
      return;
    }

    if (!this.directionService) {
      this.directionService = new this.google.maps.DirectionsService();
    }

    var result;

    try {
      result = await this.directionService?.route({
        origin: {
          placeId: this.order.addressFrom.googlePlaceId || undefined,
        },
        destination: {
          placeId: this.order.addressTo.googlePlaceId || undefined,
        },
        travelMode: TravelMode.DRIVING,
      });
    } catch (e0) {
      console.error(
        "Failed to get directions using GooglePlaceId, trying coordinates",
        e0
      );

      if (this.order.addressFrom.lat && this.order.addressTo.lat) {
        try {
          result = await this.directionService?.route({
            origin: {
              lat: Number(this.order.addressFrom.lat),
              lng: Number(this.order.addressFrom.lng),
            },
            destination: {
              lat: Number(this.order.addressTo.lat),
              lng: Number(this.order.addressTo.lng),
            },
            travelMode: TravelMode.DRIVING,
          });
        } catch (e1) {
          console.error("Failed to load directions", e1);
          this.$sideAlert("Nepodařilo se načíst trvání", "error");
        }
      }
    }

    if (!result) {
      return;
    }

    var duration = result.routes[0].legs[0].duration?.value || null;

    this.order.duration = duration || 0;
  }

  async loadOrder() {
    if (this.id <= 0) {
      return;
    }

    this.loading = true;

    if (this.passedOrder) {
      this.order = this.passedOrder;

      if (
        this.passedOrder.customer &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.customers.findIndex((x) => x.id == result.data.customer!.id) === -1
      ) {
        this.customers.push(this.passedOrder.customer);
      }
    }

    if (!this.passedOrder) {
      var result = await this.orderApi.getOrder(this.id);

      this.order = result.data;

      this.order.updateSince = this.filters.toIsoDate(new Date());

      if (
        result.data.customer &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.customers.findIndex((x) => x.id == result.data.customer!.id) === -1
      ) {
        this.customers.push(result.data.customer);
      }
    }

    this.getOrderAddMissingTimes();

    this.originalOrderState = this.order.state || OrderState.New;

    this.loading = false;
    this.id;
  }

  async saveOrUpdateOrder(mode: OrderSaveMode) {
    if (this.order.state == OrderState.New) {
      await this.saveOrder(mode);
      return;
    }

    if (this.order.orderType == OrderType.OneTime) {
      await this.saveOrder(mode);
      return;
    }

    if (this.isInstantiablePropertyChanged) {
      this.isUpdateSinceDialogOpen = true;
      return;
    } else if (this.id > 0) {
      await this.orderApi.patchOrderNoninstantiables({
        orderId: this.id,
        acceptanceType: this.order.acceptanceType,
        noteDriver: this.order.noteDriver,
        noteInternal: this.order.noteInternal,
      });
      this.$sideAlert("Uloženo", "accent darken-1");

      this.$emit("saved");

      return;
    }

    await this.saveOrder(mode);
  }

  async saveOrder(mode: OrderSaveMode) {
    this.errors = [];
    this.loading = true;

    if (mode >= OrderSaveMode.Plan && this.order.state == OrderState.New) {
      this.order.state = OrderState.Unplanned;
      this.order.updateSince = undefined;
    } else if (this.order.state == OrderState.New) {
      this.order.state = OrderState.New;
      this.order.updateSince = undefined;
    }

    this.order.times = this.order.times?.filter(
      (x) => x.pickup != null && x.dropoff != null
    );

    try {
      var result = await this.orderApi.saveOrder(this.order);

      if (
        result.data.id &&
        mode != OrderSaveMode.DraftAndDuplicate &&
        mode != OrderSaveMode.PlanAndDuplicate
      ) {
        this.$emit("saved", result.data.id);

        if (this.dialog) {
          this.id = 0;

          this.$emit("close");
        }
      } else {
        this.order.id = 0;
        this.id = 0;
        this.order.state = OrderState.New;
        this.order.addressFrom.id = 0;
        this.order.addressTo.id = 0;

        this.$emit("saved-duplicate", this.order);

        this.$router.push({
          name: "order",
          params: { id: "0" },
        });
      }

      this.isUpdateSinceDialogOpen = false;

      this.loadOrder();

      this.isInstantiablePropertyChanged = false;

      this.$sideAlert("Uloženo", "accent darken-1");
    } catch (e) {
      this.errors = parseAxiosError(e as AxiosError);
      this.order.state = this.originalOrderState;
    }

    this.loading = false;
  }

  async deleteOrder() {
    if (!this.order || this.order.id == undefined) {
      return;
    }

    this.loadingDelete = true;

    var isConfirmed = await this.$confirm(
      "Jste si jistý?",
      `Opravdu chcete odstranit objednávku?`
    );

    if (!isConfirmed) {
      this.loadingDelete = false;
      return;
    }

    if (this.order.orderType == OrderType.OneTime) {
      await this.orderApi.cancelOrder({ id: this.order.id });
      this.loadingCancel = false;
    }

    if (this.order.orderType == OrderType.Repeated) {
      await this.orderApi.cancelOrder({
        id: this.order.id,
        cancelSince: this.order.date,
      });

      this.loadingDelete = false;
    }

    this.$router.push({ name: "orders" });
    this.isCancelDialogOpen = false;
    this.$sideAlert("Objednávka byla odstraněna", "accent darken-1");
    return;
  }

  async cancelOrder(dateSince?: string | undefined) {
    if (!this.order || this.order.id == undefined) {
      return;
    }

    if (this.order.state == OrderState.New) {
      var isConfirmed = await this.$confirm(
        "Jste si jistý?",
        `Opravdu chcete odstranit rozpracovanou objednávku?`
      );

      if (!isConfirmed) {
        return;
      }

      this.loadingCancel = true;

      if (this.order.orderType == OrderType.OneTime) {
        await this.orderApi.cancelOrder({ id: this.order.id });

        this.loadingCancel = false;
      }

      if (this.order.orderType == OrderType.Repeated) {
        await this.orderApi.cancelOrder({
          id: this.order.id,
          cancelSince: this.order.date,
        });

        this.loadingCancel = false;
      }

      this.$router.push({ name: "orders" });
      this.isCancelDialogOpen = false;
      this.$sideAlert("Objednávka byla odstraněna", "accent darken-1");
      return;
    }

    if (this.order.orderType == OrderType.OneTime) {
      isConfirmed = await this.$confirm(
        "Jste si jistý?",
        `Opravdu chcete zrušit objednávku?`
      );

      if (!isConfirmed) {
        return;
      }

      this.loadingCancel = true;

      await this.orderApi.cancelOrder({ id: this.order.id });

      this.loadingCancel = false;
      this.isCancelDialogOpen = false;

      this.$sideAlert("Objednávka byla zrušena", "accent darken-1");

      this.$emit("cancelled");

      await this.loadOrder();
    }

    if (this.order.orderType == OrderType.Repeated) {
      this.loadingCancel = true;

      var response = await this.orderApi.cancelOrder({
        id: this.order.id,
        cancelSince: dateSince,
      });

      this.loadingCancel = false;
      this.isCancelDialogOpen = false;

      this.$sideAlert(
        `Objednávka byla ${response.data.deleted ? "odstraněna" : "zrušena"}.`,
        "accent darken-1"
      );

      if (response.data.deleted) {
        this.$router.push({ name: "orders" });
      }

      this.$emit("cancelled");

      await this.loadOrder();
    }
  }

  async loadSlotInstances(isReturn: boolean) {
    this.loadingSlots = true;

    if (this.order.state == OrderState.New || this.order.id == 0) {
      if (isReturn) {
        this.order.slotInstanceReturnId = undefined;
      } else {
        this.order.slotInstanceId = undefined;
      }
    }

    if (isReturn && this.returnTime.pickup && this.returnTime.dropoff) {
      let pickupTime = new Date();
      let dropoffTime = new Date();

      if (this.returnTime.primaryTime == TimeType.Pickup) {
        pickupTime = new Date(`${this.order.date} ${this.returnTime.pickup}`);
        dropoffTime = new Date(
          pickupTime.getTime() + this.order.duration * 1000
        );
      } else if (this.returnTime.primaryTime == TimeType.Dropoff) {
        dropoffTime = new Date(`${this.order.date} ${this.returnTime.dropoff}`);
        pickupTime = new Date(
          dropoffTime.getTime() - this.order.duration * 1000
        );
      }

      var resultReturn = await this.slotInstanceApi.getSlotInstancesPlanning(
        pickupTime.toISOString(),
        dropoffTime.toISOString(),
        1,
        -1,
        false
      );

      if (resultReturn.data.data) {
        this.returnSlotInstances = resultReturn.data.data;
      }

      this.loadingSlots = false;
      return;
    } else if (!isReturn && this.toTime.pickup && this.toTime.dropoff) {
      let pickupTime = new Date();
      let dropoffTime = new Date();

      if (this.toTime.primaryTime == TimeType.Pickup) {
        pickupTime = new Date(`${this.order.date} ${this.toTime.pickup}`);
        dropoffTime = new Date(
          pickupTime.getTime() + this.order.duration * 1000
        );
      } else if (this.toTime.primaryTime == TimeType.Dropoff) {
        dropoffTime = new Date(`${this.order.date} ${this.toTime.dropoff}`);
        pickupTime = new Date(
          dropoffTime.getTime() - this.order.duration * 1000
        );
      }

      var resultTo = await this.slotInstanceApi.getSlotInstancesPlanning(
        pickupTime.toISOString(),
        dropoffTime.toISOString(),
        1,
        -1,
        false
      );

      if (resultTo.data.data) {
        this.toSlotInstances = resultTo.data.data;
      }
    }

    this.loadingSlots = false;
  }

  async loadCustomer(id: number) {
    this.loadingCustomers = true;

    var result = await this.customerApi.getCustomer(id);

    if (result.data) {
      const customer: GetCustomersModel = {
        id: result.data.id,
        fullName: `${result.data.lastName} ${result.data.firstName}`,
        handicapEquipmentAbbreviation: result.data.handicapEquipmentAbbr,
      };

      this.customers.push(customer);
    }

    this.loadingCustomers = false;
  }

  async loadCustomers(search: string | null = "") {
    if (search == null) {
      search = "";
    }

    this.loadingCustomers = true;

    var result = await this.customerApi.getCustomers(
      true,
      1,
      20,
      false,
      search,
      undefined,
      undefined,
      "fullName"
    );

    if (result.data.data) {
      this.customers = result.data.data;
    }

    this.loadingCustomers = false;
  }

  async loadHandicapEquipment() {
    var result = await this.enumsApi.getHandicapEquipment();

    if (result.data) {
      this.handicapEquipment = result.data.equipments || [];
    }
  }

  async cloneOrder() {
    if (!this.order.id) {
      return;
    }

    var result = await this.orderApi.cloneOrder(this.order.id);

    if (result.data.id) {
      this.$sideAlert(
        "Objednávka zduplikována. Přesměováno na novou objednávku",
        "accent darken-1",
        6000
      );

      this.$emit("saved", result.data.id);
    }
  }
}
