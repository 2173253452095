export function toLocaleHours(
  value: string | null | undefined | Date,
  includeLeadingZero = true
) {
  if (value == null || value === undefined) {
    return "";
  }

  const date = new Date(value);
  const timeStr = date.toLocaleTimeString();

  const hourStr = timeStr.substring(0, timeStr.indexOf(":"));

  return includeLeadingZero && hourStr.length == 1 ? `0${hourStr}` : hourStr;
}
