import { HubConnectionBuilder } from "@aspnet/signalr";
import { appSettings } from "@/settings";
import { Vue } from "vue-property-decorator";
import RidesHub from "@/hubs/RidesHub";
import RidesHubEvent from "@/models/Rides/RidesHubEvent";
import { tokenStore } from "@/store";

export default {
  install() {
    const connection = new HubConnectionBuilder()
      .withUrl(`${appSettings.BaseApiUrl}hubs/ridesHub`, {
        accessTokenFactory: () => {
          const token = tokenStore.get();

          if (!token || !token.value) {
            return "";
          }

          return token.value;
        },
      })
      .build();

    const ridesHub = new RidesHub(connection);

    Vue.prototype.$ridesHub = ridesHub;

    for (const event in RidesHubEvent) {
      connection.on(
        RidesHubEvent[event as keyof typeof RidesHubEvent],
        (arg) => {
          ridesHub.$emit(
            RidesHubEvent[event as keyof typeof RidesHubEvent],
            arg
          );
        }
      );
    }

    // if connection closed, reopen it
    let startedPromise: null | Promise<any> = null;

    function start(): Promise<any> {
      startedPromise = connection
        .start()
        .then(() => {
          ridesHub.connected = true;
          if (ridesHub.onConnectedCallback) ridesHub.onConnectedCallback();
        })
        .catch(() => {
          ridesHub.connected = false;

          return new Promise((resolve, reject) =>
            setTimeout(() => start().then(resolve).catch(reject), 5000)
          );
        });

      return startedPromise;
    }

    connection.onclose(() => {
      ridesHub.connected = false;
      start();
    });

    start();
  },
};
