import Vue from "vue";

export const Events = {
  UserLoggedIn: "user-logged-in",
  UserLoggedOut: "user-logged-out",
  ShowSideAlert: "show-side-alert",
  Confirm: "confirm",
  UsersUpdated: "users-updated",
  OperatingDaysUpdated: "operating-days-updated",
};

export const eventBus = new Vue();
