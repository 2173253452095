
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { OrderDayModel } from "@/api";
import { TimeType } from "@/models/Orders/OrderEnums";
import { TimeUnit, getCorrectedRideDurationInSeconds } from "@/utils";

@Component({
  name: "RideTimeRangeInput",
})
export default class RideTimeRangeInput extends Vue {
  @Prop()
  value: OrderDayModel;

  @Prop()
  fromLabel: string;

  @Prop()
  toLabel: string;

  @Prop()
  disabled: boolean;

  @Prop()
  duration: number | null;

  timeTypes = TimeType;

  internalData: OrderDayModel = {
    dropoff: "",
    isReturn: false,
    pickup: "",
    primaryTime: 1,
    weekday: 0,
  };

  @Watch("duration", { immediate: true })
  onDurationChanged() {
    if (this.duration === null || !this.value.primaryTime) {
      return;
    }

    var primaryTime =
      this.value.primaryTime == TimeType.Dropoff
        ? this.value.dropoff
        : this.value.pickup;

    if (!primaryTime) {
      return;
    }

    this.setTimeBasedOnDuration(
      this.duration,
      this.value.primaryTime,
      primaryTime
    );

    this.$emit("input", this.value);
  }

  @Watch("value", { immediate: true, deep: true })
  onValueChanged() {
    this.internalData = JSON.parse(JSON.stringify(this.value));
  }

  setTimeBasedOnDuration(duration: number, timeType: TimeType, time: string) {
    var timeSplit = time.split(":").map((x) => Number(x));
    var currentDate = new Date();

    console.log(duration);

    duration = getCorrectedRideDurationInSeconds(duration, TimeUnit.Seconds);

    if (timeType == TimeType.Dropoff) {
      currentDate.setHours(timeSplit[0]);
      currentDate.setMinutes(timeSplit[1]);

      currentDate.setSeconds(currentDate.getSeconds() - duration);

      this.value.pickup = currentDate.toTimeString().substring(0, 5);

      return;
    }

    currentDate.setHours(timeSplit[0]);
    currentDate.setMinutes(timeSplit[1]);

    currentDate.setSeconds(currentDate.getSeconds() + duration);

    this.value.dropoff = currentDate.toTimeString().substring(0, 5);
  }

  setTimeChanged(time: string | null, isDropoff = false) {
    var timeType = isDropoff ? TimeType.Dropoff : TimeType.Pickup;

    if (time && this.duration) {
      this.setTimeBasedOnDuration(this.duration, timeType, time);
    }

    if (isDropoff) {
      this.value.dropoff = time || undefined;
      this.value.primaryTime = TimeType.Dropoff;
    } else {
      this.value.pickup = time || undefined;
      this.value.primaryTime = TimeType.Pickup;
    }

    this.$emit("input", this.value);
  }
}
