class AppSettings {
  BaseApiUrl = "";
  GoogleMapsApiKey = "";
  ForceReloadBoardAfterHubEvents = 10;

  loadFromObject(obj: any): void {
    this.BaseApiUrl = obj.BaseApiUrl;
    this.GoogleMapsApiKey = obj.GoogleMapsApiKey;
    this.ForceReloadBoardAfterHubEvents = obj.ForceReloadBoardAfterHubEvents;
  }
}

export const appSettings = new AppSettings();
