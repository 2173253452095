import { stringNullOrEmpty } from "@/utils";
import { AddressModel } from "./../api/api";

export function toAddressText(
  address: AddressModel | null | undefined
): string {
  if (!address) {
    return "";
  }

  if (stringNullOrEmpty(address.street)) {
    return "";
  }

  return `${address.street} ${address.streetNumber}, ${address.postCode} ${address.city}`;
}
