enum AddressComponentType {
  administrative_area_level_1 = "administrative_area_level_1",
  administrative_area_level_2 = "administrative_area_level_2",
  administrative_area_level_3 = "administrative_area_level_3",
  administrative_area_level_4 = "administrative_area_level_4",
  administrative_area_level_5 = "administrative_area_level_5",
  administrative_area_level_6 = "administrative_area_level_6",
  administrative_area_level_7 = "administrative_area_level_7",
  archipelago = "archipelago",
  colloquial_area = "colloquial_area",
  continent = "continent",
  country = "country",
  establishment = "establishment",
  finance = "finance",
  floor = "floor",
  food = "food",
  general_contractor = "general_contractor",
  geocode = "geocode",
  health = "health",
  intersection = "intersection",
  landmark = "landmark",
  locality = "locality",
  natural_feature = "natural_feature",
  neighborhood = "neighborhood",
  place_of_worship = "place_of_worship",
  plus_code = "plus_code",
  point_of_interest = "point_of_interest",
  political = "political",
  post_box = "post_box",
  postal_code = "postal_code",
  postal_code_prefix = "postal_code_prefix",
  postal_code_suffix = "postal_code_suffix",
  postal_town = "postal_town",
  premise = "premise",
  room = "room",
  route = "route",
  street_address = "street_address",
  street_number = "street_number",
  sublocality = "sublocality",
  sublocality_level_1 = "sublocality_level_1",
  sublocality_level_2 = "sublocality_level_2",
  sublocality_level_3 = "sublocality_level_3",
  sublocality_level_4 = "sublocality_level_4",
  sublocality_level_5 = "sublocality_level_5",
  subpremise = "subpremise",
  town_square = "town_square",
}

export default AddressComponentType;
