import { OrderInstanceState } from "@/models/Orders/OrderEnums";

export function orderInstanceStateToColor(state: OrderInstanceState | any) {
  switch (state) {
    case OrderInstanceState.Unplanned:
      return "secondary";
    case OrderInstanceState.Planned:
      return "accent";
    case OrderInstanceState.Ongoing:
      return "accent";
    case OrderInstanceState.Cancelled:
      return "error";
    case OrderInstanceState.DidNotShowUp:
      return "warning";
    case OrderInstanceState.Finished:
      return "primary";
    default:
      return "secondary";
  }
}
