import { HandicapEquipment } from "@/api/api";
import { Escort } from "@/models/Orders/OrderEnums";

export function getEscortOptionSheet(
  opt: {
    key: string;
    handicapEquipment: HandicapEquipment | null;
    escort: Escort | null;
  },
  isInMenu = true
) {
  if (opt.escort == null) {
    return {
      class: "rounded-circle",
      style: isInMenu
        ? ""
        : "width:44px; height:44px; padding-top: 9px !important; margin-top: 4px",
    };
  }

  if (opt.escort == Escort.ChildLittle) {
    return {
      class: "kite-shape",
    };
  }

  if (opt.escort == Escort.Adult) {
    return {
      class: "rounded-circle",
      style: isInMenu
        ? ""
        : "width:44px; height:44px; padding-top: 9px !important; margin-top: 4px",
    };
  }

  return {
    style: isInMenu
      ? ""
      : "width:44px; height:44px; padding-top: 9px !important; margin-top: 4px",
  };
}
