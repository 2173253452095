
import { OrderType } from "@/models/Orders/OrderEnums";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "OrderTypeToggle",
})
export default class OrderTypeToggle extends Vue {
  @Prop()
  value: OrderType;

  @Prop()
  disabled: boolean;

  orderTypes = OrderType;
}
