import { toIsoDate } from "@/filters/IsoDateTimeToIsoDateFilter";
import { toBillingZone } from "@/filters/numberToBillingZone";
import { toAddressText } from "@/filters/addressFilter";
import { toLocaleDateTime } from "@/filters/IsoDateTimeToLocaleDateTime";
import { toLocaleDate } from "@/filters/ToLocaleDate";
import { toLocaleTime } from "@/filters/ToLocaleTime";
import { toLocaleHours } from "@/filters/ToLocaleHours";
import { getIconFromEquipmentAbbr } from "@/filters/getIconFromEquipmentAbbr";
import { getColorFromEquipmentAbbr } from "@/filters/getColorFromEquipmentAbbr";
import { getColorFromVehicleType } from "@/filters/getColorFromVehicleType";
import { orderStateToText } from "@/filters/orderStateToText";
import { getColorFromOrderState } from "@/filters/orderStateToColor";
import { getIconFromRole } from "@/filters/getIconFromRole";
import { getColorFromRole } from "@/filters/getColorFromRole";
import { getEscortTranslation } from "@/filters/getEscortTranslation";
import { orderInstanceStateToText } from "@/filters/orderInstanceStateToText";
import { orderInstanceStateToColor } from "@/filters/orderInstanceStateToColor";
import { getColorFromEquipment } from "@/filters/getColorFromEquipment";
import { getIconFromEquipment } from "@/filters/getIconFromEquipment";
import { getColorFromOrderType } from "@/filters/getColorFromOrderType";
import { getTextFromOrderType } from "@/filters/getTextFromOrderType";
import { getEscortOptionSheet } from "@/filters/getEscortOptionSheet";
import { getEscortFromAge } from "@/filters/getEscortFromAge";
import { getTextFromEventType } from "@/filters/getTextFromEventType";
import { getWeekdayName } from "@/filters/getWeekdayName";
import { getDimColorFromVehicleType } from "./getDimColorFromVehicleType";

export const filters = {
  toIsoDate: toIsoDate,
  toBillingZone: toBillingZone,
  toAddressText: toAddressText,
  toLocaleDateTime: toLocaleDateTime,
  toLocaleDate: toLocaleDate,
  toLocaleTime: toLocaleTime,
  toLocaleHours: toLocaleHours,
  getColorFromEquipmentAbbr: getColorFromEquipmentAbbr,
  getIconFromEquipmentAbbr: getIconFromEquipmentAbbr,
  getColorFromVehicleType: getColorFromVehicleType,
  orderStateToText: orderStateToText,
  getColorFromOrderState: getColorFromOrderState,
  getIconFromRole: getIconFromRole,
  getColorFromRole: getColorFromRole,
  getEscortTranslation: getEscortTranslation,
  orderInstanceStateToText: orderInstanceStateToText,
  orderInstanceStateToColor: orderInstanceStateToColor,
  getColorFromEquipment: getColorFromEquipment,
  getIconFromEquipment: getIconFromEquipment,
  getColorFromOrderType: getColorFromOrderType,
  getTextFromOrderType: getTextFromOrderType,
  getEscortOptionSheet: getEscortOptionSheet,
  getEscortFromAge: getEscortFromAge,
  getTextFromEventType: getTextFromEventType,
  getWeekdayName: getWeekdayName,
  getDimColorFromVehicleType: getDimColorFromVehicleType,
};
