export function getColorFromVehicleType(type: string): string {
  switch (type) {
    case "S":
      return "accent";
    case "s":
      return "success";
    case "M":
      return "primary";
    case "L":
      return "warning";
    default:
      return "error";
  }
}
