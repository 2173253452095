import SideAlertModel from "@/models/SideAlertModel";
import { eventBus, Events } from "@/utils/event-bus";

export const sideAlertMixin = {
  methods: {
    $sideAlert(text: string, color = "primary darken-1", timeout = 3000) {
      const model = new SideAlertModel(text, color, timeout);

      eventBus.$emit(Events.ShowSideAlert, model);
    },
  },
};
