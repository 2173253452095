export function getIconFromEquipmentAbbr(
  abbr: string | null | undefined
): string {
  switch (abbr) {
    case "C":
      return "mdi-shoe-print";
    case "M":
      return "mdi-wheelchair-accessibility";
    case "E":
      return "mdi-wheelchair";
    case "K":
      return "mdi-baby-carriage";
    case null:
    case undefined:
      return "mdi-account-child";
    default:
      return abbr;
  }
}
