import { render, staticRenderFns } from "./SideAlerts.vue?vue&type=template&id=33e8c104&scoped=true"
import script from "./SideAlerts.vue?vue&type=script&lang=ts"
export * from "./SideAlerts.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e8c104",
  null
  
)

export default component.exports