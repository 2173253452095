import { Escort } from "@/models/Orders/OrderEnums";

export function getEscortFromAge(age: number | undefined) {
  if (age == undefined) {
    return Escort.ChildLittle;
  }

  if (age < 6) {
    return Escort.ChildLittle;
  }

  if (age < 18) {
    return Escort.Child;
  } else {
    return Escort.Adult;
  }
}
