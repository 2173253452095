import { Role } from "@/models/Role";

export function getColorFromRole(role: Role): string {
  switch (role) {
    case Role.Administrator:
      return "warning";
    case Role.Dispatcher:
      return "accent";
    case Role.Carrier:
      return "accent";
    case Role.Analyst:
      return "accent";
    case Role.Driver:
      return "primary";
    case Role.Assistant:
      return "primary";
    default:
      return "error";
  }
}
