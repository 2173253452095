
import { Component, Vue } from "vue-property-decorator";

import ConfirmDialogOptions from "@/models/ConfirmDialogOptions";
import { eventBus, Events } from "@/utils/event-bus";
import ConfirmDialogModel from "@/models/ConfirmDialogModel";

@Component({
  name: "ConfirmDialog",
})
export default class ConfirmDialog extends Vue {
  isDialogOpen = false;

  resolve: ((value: boolean) => void) | null;
  reject: ((reason?: any) => void) | null = null;

  message: string | null = null;
  title: string | null = null;
  options: ConfirmDialogOptions = new ConfirmDialogOptions();

  mounted() {
    eventBus.$on(Events.Confirm, this.open);
  }

  open(model: ConfirmDialogModel) {
    this.title = model.title;
    this.message = model.message;
    this.options = Object.assign(this.options, model.options);

    this.resolve = model.resolve;
    this.reject = model.reject;

    this.isDialogOpen = true;
  }

  agree() {
    if (this.resolve) {
      this.resolve(true);
    }

    this.isDialogOpen = false;
  }

  cancel() {
    if (this.resolve) {
      this.resolve(false);
    }
    this.isDialogOpen = false;
  }
}
