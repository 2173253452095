import { OrderState } from "@/models/Orders/OrderEnums";

export function getColorFromOrderState(orderState: OrderState) {
  switch (orderState) {
    case OrderState.New:
      return "secondary lighten-2";
    case OrderState.PartialyPlanned:
      return "secondary";
    case OrderState.Cancelled:
      return "error";
    case OrderState.Finished:
      return "primary";
    case OrderState.Planned:
      return "accent";
    case OrderState.Unplanned:
      return "secondary";

    default:
      return "secondary";
  }
}
