export function toBillingZone(number: number) {
  switch (number) {
    case 1:
      return "P";
    case 2:
      return "Z";
    case 3:
      return "S";
    case 4:
      return "X";

    default:
      return "";
  }
}
