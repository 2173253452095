import { OrderState } from "@/models/Orders/OrderEnums";

export function orderStateToText(orderState: OrderState) {
  switch (orderState) {
    case OrderState.New:
      return "Nová";
    case OrderState.PartialyPlanned:
      return "Rozplánovaná";
    case OrderState.Cancelled:
      return "Zrušená";
    case OrderState.Finished:
      return "Dokončnená";
    case OrderState.Planned:
      return "Naplánovaná";
    case OrderState.Unplanned:
      return "Nenaplánovaná";

    default:
      return "";
  }
}
