export enum OrderType {
  OneTime = 1,
  Repeated,
}

export enum AcceptanceType {
  Form = 1,
  Email,
  Phone,
  Other,
}

export enum OrderState {
  New = 1,
  Unplanned,
  PartialyPlanned,
  Planned,
  Cancelled,
  Finished,
}

export enum OrderSaveMode {
  Draft = 1,
  DraftAndDuplicate,
  Plan,
  PlanAndDuplicate,
}

export enum OrderInstanceState {
  Unplanned = 1,
  Planned,
  Ongoing,
  DidNotShowUp,
  Cancelled,
  Finished,
}

export enum OrderInstanceCancellationType {
  CancelledByDispatcher = 1,
  CancelledOnTime,
  CancelledLate,
  NotFilled,
  NotFilledDefinitive,
}

export enum OrderRepetitionFrequency {
  All = 1,
  EvenWeeks,
  OddWeeks,
}

export enum Escort {
  ChildLittle = 1,
  Child,
  Adult,
}

export enum TimeType {
  Pickup = 1,
  Dropoff,
}

export enum RideStopType {
  Pickup = 1,
  Dropoff,
}
