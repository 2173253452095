import { UserApi } from "@/api";
import { refreshTokenStore, tokenStore } from "@/store";
import { getDefaultApiConfig, logOut } from "@/utils";

export default {
  install() {
    /* 5 Minutes interval */
    refreshToken();
    setInterval(refreshToken, 1000 * 60 * 5);

    async function refreshToken() {
      const refreshToken = refreshTokenStore.get();
      const token = tokenStore.get();

      if (!token || !refreshToken) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const expireDate = new Date(token.expiryDate!);
      const currentDate = new Date();

      currentDate.setMinutes(currentDate.getMinutes() + 10);

      if (expireDate >= currentDate) {
        return;
      }

      const userApi = new UserApi(getDefaultApiConfig(false));

      try {
        const response = await userApi.refreshToken({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          accessToken: token.value!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          refreshToken: refreshToken.value!,
        });

        tokenStore.set(response.data.accessToken);
        refreshTokenStore.set(response.data.refreshToken);

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return response.data.accessToken!.value!;
      } catch (error) {
        logOut();
        throw new Error("Cannot refresh token");
      }
    }
  },
};
