
import { AddressModel, AddressApi, AddressType } from "@/api";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { initGoogleMaps, ValidationRules, getDefaultApiConfig } from "@/utils";
import AddressComponentType from "@/models/GoogleMaps/AddressComponentType";
import { getGoogleMapsAPI } from "gmap-vue";
import { debounce } from "vue-debounce";

@Component({
  name: "AddressDialog",
})
export default class AddressDialog extends Vue {
  @Prop()
  value: AddressModel;

  @Prop()
  readonly: boolean;

  @Prop({ default: false })
  hideName: boolean;

  @Prop({ default: false })
  hideComment: boolean;

  @Prop({ default: false })
  hideGlobalAddresses: boolean;

  @Prop({ default: 0 })
  customerId: number | null;

  @Prop({ default: 0 })
  addressDialogOpen: number | undefined;

  @Prop({ default: true })
  allowEditing: boolean;

  @Prop({ default: 0 })
  addressType: AddressType;

  isMounted = false;

  addressApi = new AddressApi(getDefaultApiConfig());

  globalAddresses: AddressModel[] = [];
  debouncedLoadAddresses = debounce(this.loadAddresses, 200);

  selectedGlobalAddress: number | null = null;

  addressSearch = "";

  loadingAddresses = false;
  globalAddressSearch = "";

  error: string | null = null;

  address: AddressModel = {
    billingZone: 1,
    city: "",
    comment: "",
    id: 0,
    name: "",
    postCode: "",
    streetNumber: "",
    street: "",
    notFound: false,
  };

  // eslint-disable-next-line no-undef
  autocompleteOptions: google.maps.places.AutocompleteOptions = {
    componentRestrictions: {
      country: "cz",
    },
    fields: ["place_id", "address_components", "geometry"],
    types: ["route"],
  };

  // eslint-disable-next-line no-undef
  geocoder: google.maps.Geocoder | null = null;

  validationRules = ValidationRules;

  defaultAddress: AddressModel = {
    city: "",
    comment: "",
    id: 0,
    name: "",
    postCode: "",
    streetNumber: "",
    street: "",
    isActive: true,
  };

  @Watch("value", { immediate: true, deep: true })
  onValueChanged() {
    this.address = Object.assign({}, this.defaultAddress);

    if (this.$refs["form"]) {
      (this.$refs["form"] as any).resetValidation();
    }
    this.address = Object.assign({}, this.value);

    this.addressSearch = "";
  }

  @Watch("customerId", { immediate: true, deep: true })
  onCustomerChanged() {
    if (this.customerId !== 0 && this.isMounted) {
      this.selectedGlobalAddress = 0;
      this.loadAddresses();
    }
  }

  @Watch("selectedGlobalAddress", { immediate: true, deep: true })
  async onSelectedGlobalAddressChanged() {
    if (!this.isMounted) {
      return;
    }

    if (this.selectedGlobalAddress == null) {
      return;
    }

    if (this.selectedGlobalAddress === 0) {
      return;
    }

    this.globalAddressSearch =
      this.globalAddresses.find((x) => x.id == this.selectedGlobalAddress)
        ?.name ?? "";

    await this.loadAddresses(this.globalAddressSearch);

    this.addressSearch = "";

    this.address = Object.assign({}, this.defaultAddress);

    if (this.$refs["form"]) {
      (this.$refs["form"] as any).resetValidation();
    }

    if (!this.selectedGlobalAddress) {
      return;
    }

    var a = this.globalAddresses.find(
      (x) => x.id == this.selectedGlobalAddress
    );

    if (!a) {
      return;
    }

    this.address = a;
  }

  get google() {
    return getGoogleMapsAPI();
  }

  mounted() {
    initGoogleMaps();

    if (!this.allowEditing && this.value.addressType != 2) {
      this.selectedGlobalAddress = this.value.id ?? null;
    }

    this.address = Object.assign({}, this.value);

    this.isMounted = true;
  }

  async loadAddresses(search: string | null = "") {
    if (search == null) {
      search = "";
    }

    var result = await this.addressApi.getAddresses(
      true,
      1,
      25,
      false,
      this.customerId == null || this.customerId == 0
        ? undefined
        : this.customerId,
      undefined,
      undefined,
      this.customerId == 0 ? [3, 4] : [1, 3, 4],
      "addresstype",
      search
    );

    if (result.data.data) {
      this.globalAddresses = result.data.data;
    }
  }

  async onSaveButtonClick() {
    this.error = null;

    if (this.readonly) {
      return;
    }
    if (!this.$refs["form"] || !(this.$refs["form"] as any).validate()) {
      return;
    }

    if (!this.address.googlePlaceId && !this.address.lat) {
      if (!(await this.geoCodeAddress())) {
        return;
      }
    }

    this.$emit("input", this.address);
    this.$emit("save", this.address);

    this.address = Object.assign({}, this.defaultAddress);
  }

  async geoCodeAddress() {
    if (!this.geocoder) {
      this.geocoder = new this.google.maps.Geocoder();
    }

    try {
      var result = await this.geocoder?.geocode({
        componentRestrictions: {
          country: "cz",
          postalCode: this.address.postCode,
          route: this.address.street,
        },
        address: this.filters.toAddressText(this.address),
      });

      if (result && result.results.length > 0) {
        var bestResult = result.results.sort((x, y) =>
          x.partial_match === y.partial_match ? 0 : x.partial_match ? 1 : -1
        )[0];

        this.address.googlePlaceId = bestResult.place_id;

        this.address.lat = bestResult.geometry.location.lat();
        this.address.lng = bestResult.geometry.location.lng();

        this.address.notFound = false;

        return true;
      }
    } catch (e) {
      const code = (e as any).code;

      if (code == "ZERO_RESULTS") {
        this.address.notFound = true;
      }

      this.error = "Nepodařilo se nám vyhledat adresu";
    }

    return false;
  }

  resetGoogleResponse() {
    this.address.googlePlaceId = null;
    this.address.lat = null;
    this.address.lng = null;
  }

  // eslint-disable-next-line no-undef
  onPlaceChanged(place: google.maps.places.PlaceResult) {
    this.selectedGlobalAddress = 0;

    if (!this.allowEditing) {
      this.address.id = 0;
    }

    if (!this.hideGlobalAddresses) {
      this.address.name = "";
      this.address.streetNumber = "";
    }

    var postCode = place.address_components?.find((x) =>
      x.types.some((t) => t == AddressComponentType.postal_code)
    )?.long_name;

    if (postCode) {
      this.address.postCode = postCode;
    }

    var street = place.address_components?.find((x) =>
      x.types.some((t) => t == AddressComponentType.route)
    )?.long_name;

    if (street) {
      this.address.street = street;
    }

    var streetNumber = place.address_components?.find((x) =>
      x.types.some((t) => t == AddressComponentType.street_number)
    )?.long_name;

    if (streetNumber) {
      this.address.streetNumber = streetNumber;
      this.address.googlePlaceId = place.place_id || null;
      this.address.lat = place.geometry?.location?.lat() || null;
      this.address.lng = place.geometry?.location?.lng() || null;
    } else {
      this.resetGoogleResponse();
    }

    var possibleCities = place.address_components?.filter((x) =>
      x.types.some(
        (t) =>
          t == AddressComponentType.political ||
          t == AddressComponentType.postal_town
      )
    );

    if (possibleCities && possibleCities.length > 0) {
      this.address.city = this.parseCity(possibleCities);
    }

    this.address.addressType = this.addressType;
  }

  // eslint-disable-next-line no-undef
  parseCity(addrrComponents: google.maps.GeocoderAddressComponent[]) {
    var city = addrrComponents.find((x) =>
      x.types.some((t) => t == AddressComponentType.postal_town)
    );

    if (city) {
      return city.long_name;
    }

    city = addrrComponents.find((x) =>
      x.types.some((t) => t == AddressComponentType.locality)
    );

    if (city) {
      return city.long_name;
    }

    city = addrrComponents.find((x) =>
      x.types.some((t) => t == AddressComponentType.sublocality)
    );

    if (city) {
      return city.long_name;
    }

    return addrrComponents[0].long_name;
  }

  onCancelButtonClick() {
    this.address = Object.assign({}, this.defaultAddress);
    this.$emit("cancel");
  }
}
