
import { AcceptanceType } from "@/models/Orders/OrderEnums";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "AcceptanceTypeToggle",
})
export default class AcceptanceTypeToggle extends Vue {
  @Prop()
  value: AcceptanceType;

  acceptanceTypes = AcceptanceType;
}
