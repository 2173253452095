import VDataFooter from "vuetify/lib/components/VDataIterator/VDataFooter";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import cs from "vuetify/src/locale/cs";

Vue.use(Vuetify);

VDataFooter.options.props.itemsPerPageOptions.default = () => [25, 50, 100, -1];

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#14488c",
        secondary: "#808080",
        accent: "#00a5ab",
        error: "#d8234a",
        info: "#1c1c1e",
        success: "#339966",
        warning: "#f49818",
      },
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { cs },
    current: "cs",
  },
});
