export function getDimColorFromVehicleType(type: string): string {
  switch (type) {
    case "S":
      return "#bfe8ea";
    case "s":
      return "#B7E5CE";
    case "M":
      return "#c4d1e2";
    case "L":
      return "#fce5c5";
    default:
      return "#f5c8d2";
  }
}
