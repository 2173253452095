
import SideAlertModel from "@/models/SideAlertModel";
import { eventBus, Events } from "@/utils/event-bus";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "SideAlerts",
})
export default class SideAlerts extends Vue {
  isVisible = false;

  model: SideAlertModel = new SideAlertModel("", "primary", 3000);

  mounted() {
    eventBus.$on(Events.ShowSideAlert, this.onShowSideAlert);
  }

  onShowSideAlert(sideAlert: SideAlertModel) {
    this.model = sideAlert;
    this.isVisible = true;
  }
}
