export function toLocaleDate(value: string | null | Date) {
  if (value == null) {
    return "";
  }

  if (value instanceof Date) {
    return value.toLocaleDateString();
  }

  const date = new Date(value);

  return date.toLocaleDateString();
}
