import ConfirmDialogModel from "@/models/ConfirmDialogModel";
import ConfirmDialogOptions from "@/models/ConfirmDialogOptions";
import { eventBus, Events } from "@/utils/event-bus";

export const confirmMixin = {
  methods: {
    $confirm(
      title: string,
      message: string,
      options: ConfirmDialogOptions = new ConfirmDialogOptions()
    ): Promise<boolean> {
      return new Promise((resolve, reject) => {
        eventBus.$emit(
          Events.Confirm,
          new ConfirmDialogModel(title, message, options, resolve, reject)
        );
      });
    },
  },
};
