import ConfirmDialogOptions from "@/models/ConfirmDialogOptions";
export default class ConfirmDialogModel {
  title: string;
  message: string;
  options: ConfirmDialogOptions;

  resolve: (value: boolean) => void;
  reject: (reason?: any) => void;

  constructor(
    title: string,
    message: string,
    options: ConfirmDialogOptions = new ConfirmDialogOptions(),
    resolve: (value: boolean) => void,
    reject: (reason?: any) => void
  ) {
    this.title = title;
    this.message = message;
    this.options = options;
    this.resolve = resolve;
    this.reject = reject;
  }
}
