export function getColorFromEquipmentAbbr(
  abbr: string | null | undefined
): string {
  switch (abbr) {
    case "C":
      return "warning";
    case "M":
      return "primary";
    case "E":
      return "accent";
    case "K":
      return "error";
    case null:
    case undefined:
      return "secondary";
    default:
      return abbr;
  }
}
