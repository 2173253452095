import { EventType } from "@/models/Shiftlog/EventType";

export function getTextFromEventType(value: EventType | undefined) {
  if (value == undefined) {
    return "událost";
  }
  switch (value) {
    case EventType.ShiftStart:
      return "zahájení směny";
    case EventType.ShiftEnd:
      return "konec směny";
    case EventType.BreakStart:
      return "zahájení přestávky";
    case EventType.BreakEnd:
      return "konec přestávky";
    case EventType.Pickup:
      return "naložení";
    case EventType.Dropoff:
      return "vyložení";
    case EventType.DidNotShowUp:
      return "nedorazil";
    default:
      return "událost";
  }
}
